import { IconButton, Tooltip } from '@mui/material';
import React from 'react'
import {FaGithub} from "react-icons/fa";
import {MdEmail} from "react-icons/md";
import "../styles/Home.css";

function Home() {

  return (
    <div className='home'>
      <div className='about'>
        <h2>Hi, My name is Val</h2>
        <div className='prompt'>
          <p>
          A web developer specializes in building scalable web applications using Django framework.
          </p>
          <Tooltip title="valmelbin.cabitac@hotmail.com">
            <IconButton>
              <button style={{background:"none", border:"none"}}>
                <a href='mailto:valmelbin.cabitac@hotmail.com' rel='noreferrer'><MdEmail /></a>
              </button>
            </IconButton>
          </Tooltip>
          <Tooltip title="coder-val">
            <IconButton>
            <button style={{background:"none", border:"none"}}>
              <a href='https://github.com/coder-val' rel='noreferrer'><FaGithub /></a>
            </button>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className='skills'>
        <h1>Skills</h1>
        <ol className='list'>
          <li className='item'>
            <h2>Front-End</h2>
            <span>ReactJS, HTML, CSS, MaterialUI, Bootstrap</span>
          </li>
          <li className='item'>
            <h2>Back-End</h2>
            <span>Django, MySQL, MS SQL, MongoDB, Firebase</span>
          </li>
          <li className='item'>
            <h2>Languages</h2>
            <span>Python, C#, PHP, Javascript</span>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default Home