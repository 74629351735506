import React from 'react'
import { useParams } from 'react-router-dom'
import { projectList } from '../helpers/ProjectList'
import {FaGithub} from "react-icons/fa";
import '../styles/ProjectDisplay.css'


function ProjectDisplay() {

    const {id} = useParams();
    const project = projectList[id];

    return (
        <div className='project'>
            <h1>{project.name}</h1>
            <a href={project.link}>
                <img src={project.image}/>
            </a>
            <p><b>Skills: </b>{project.skills}</p>
            <a href={project.github}><FaGithub /></a>
        </div>
    )
}

export default ProjectDisplay