import React from 'react'
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import {MdSchool, MdWork} from "react-icons/md"
import "../styles/Experience.css"

function Experience() {
  return (
    <div className='experience'>
      <VerticalTimeline lineColor='#3e497a'>

        <VerticalTimelineElement className='vertical-timeline-element--education' date='2007 - 2013' iconStyle={{background:"#3e497a", color:"#fff"}} icon={<MdSchool />}>
          <h3 className='vertical-timeline-element-title'>Villa Elementary School</h3>
          <p>Valedictorian</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement className='vertical-timeline-element--education' date='2013 - 2019' iconStyle={{background:"#3e497a", color:"#fff"}} icon={<MdSchool />}>
          <h3 className='vertical-timeline-element-title'>Saint Francis Academy – Santa Teresita Incorporated</h3>
          <p>High School Diploma with Honors</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement className='vertical-timeline-element--education' date='2019 - 2023' iconStyle={{background:"#3e497a", color:"#fff"}} icon={<MdSchool />}>
          <h3 className='vertical-timeline-element-title'>Cagayan State University – Gonzaga Campus</h3>
          <h4 className='vertical-timeline-element-subtitle'>Bachelor's Degree</h4>
          <p>Information Technology</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement className='vertical-timeline-element--work' date='2022' iconStyle={{background:"orange", color:"#fff"}} icon={<MdWork />}>
          <h3 className='vertical-timeline-element-title'>Awit Shop – Stay safe. Shop Online!</h3>
          <h4 className='vertical-timeline-element-subtitle'>E-Commerce Website</h4>
          <p>Designed an E-Commerce website theme.</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement className='vertical-timeline-element--work' date='2023 - present' iconStyle={{background:"orange", color:"#fff"}} icon={<MdWork />}>
          <h3 className='vertical-timeline-element-title'>Attendance Management System with Facial Recognition – SFASTI</h3>
          <h4 className='vertical-timeline-element-subtitle'>Capstone Project</h4>
          <p>Developed a web-based attendance system through facial recognition for Saint Francis Academy.</p>
        </VerticalTimelineElement>

      </VerticalTimeline>
    </div>
  )
}

export default Experience