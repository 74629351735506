import React from 'react'
import '../styles/Footer.css'
import {FaFacebook, FaGithub} from "react-icons/fa";
import {MdEmail} from "react-icons/md";

function Footer() {
  return (
    <div className='footer'>
      <div className='socialMedia'>
        <a href='https://facebook.com/valcabitac' target="_blank" rel="noreferrer"><FaFacebook /></a>
        <a href='mailto:valmelbin.cabitac@hotmail.com'><MdEmail /></a>
        <a href='https://github.com/coder-val' target="_blank" rel="noreferrer"><FaGithub /></a>
      </div>
      <p>&copy; 2023 All Rights Reserved</p>
    </div>
  )
}

export default Footer