import Project1 from '../assets/awitshop.png'
import Project2 from '../assets/amsfr.png'

export const projectList = [
    {
        name: "Awit Shop",
        image: Project1,
        skills: "HTML, CSS, Javascript",
        github: "https://github.com/coder-val/awit-shop",
        link: "https://awit-shop.pages.dev",
    },
    {
        name: "AMSFR",
        image: Project2,
        skills: "Python, Django, MySQL, HTML, CSS, Javascript, MaterialUI",
        github: "https://github.com/coder-val/AMSFR",
        link: "javascript:void(0)",
    }
]